<div class="advantage-item">
  <div class="advantage-item__picture">
    <div class="advantage-picture top" *ngIf="advantage.picture === 'tick'">
      <div class="circle clr--yellow">
        <svg-icon src="checked.svg"></svg-icon>
      </div>
    </div>

    <div class="advantage-picture bottom" *ngIf="advantage.picture === 'dialog'">
      <div class="circle clr--yellow">
        <svg-icon src="dots.svg"></svg-icon>
      </div>
    </div>

    <div class="advantage-picture" *ngIf="advantage.picture === 'profile'">
      <div class="cube">
        <span></span>
      </div>
    </div>
  </div>
  <div class="advantage-item__main">
    <h3 class="fw--700">{{ advantage?.title | translate }}</h3>
    <p class="fw--400 fz--14">{{ advantage?.description | translate }}</p>
  </div>
</div>
