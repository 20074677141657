<div class="blog-item">
  <img src="/assets/images/photo.webp" alt="Photo">
  <div class="blog-item__main">
    <div class="blog-item__title">Why we do what we do</div>
    <div class="blog-item__content">
      Why we do what we do
      If your values are not invested in the business - he has no face. We are for people and about people. In other words - "your call is really important to us"
    </div>
    <div class="blog-item__author">
      Oksana
    </div>
  </div>
  <div class="blog-item__btn">
    <svg-icon src="arrow.svg"></svg-icon>
  </div>
</div>
