import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-advantage-item',
  templateUrl: './advantage-item.component.html',
  styleUrls: ['./advantage-item.component.scss']
})
export class AdvantageItemComponent {
  @Input() advantage: any;

  constructor() {
  }
}
